@import "../../../../../styles/_mixins.scss";
.section {
    position: relative;
    background-color: #14284E;
    border-top: 1px solid #ffffff;
    padding-bottom: 237px;

    @include breakpoint(d) {
        border-top: 0;
        padding-bottom: 0;
    }
}

.background_box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: right;
    z-index: 0;
}

.background_img {
    height: 100%;
    object-fit: cover;
    overflow: visible;
    
    @include breakpoint(d) {
        height: 712px;    
    }

    @include breakpoint(t) {
        height: 600px;
    }

    @include breakpoint(m) {
        height: 500px;
    }

    @include breakpoint(a) {
        height: 400px;
    }

    @include breakpoint(s) {
        height: 300px;
    }

}

.gradient {
    position: absolute;
    top: 0;
    right: 700.2px;
    width: 489px;
    height: 100%;
    // 가로, 0% : #14284E 100% -> 100% : #FFFFFF 0%
    background: linear-gradient(90deg, rgba(20, 40, 78, 1) 0%, rgba(20, 40, 78, 0) 100%);
        z-index: 1;
}

.container {
    display: flex;
    flex-direction: column;

    @include breakpoint(d) {
        padding: 732px 52px 102px 56px;
    }

    @include breakpoint(t) {
        padding: 616px 52px 102px 56px;
    }

    @include breakpoint(m) {
        padding: 508px 26px 51px 28px;
    }

    @include breakpoint(a) {
        padding: 404px 26px 51px 28px;
    }

    @include breakpoint(s) {
        padding: 302px 26px 51px 28px;
    }
}

.title_box {
    width:401px;
    padding:138px 0 0;
    color: #FFF;
    @include poppins-bold;
    font-size: 40px;

    @include breakpoint(d) {
        width: 100%;
        padding: 0;
        font-size: 64px;
    }

    @include breakpoint(m) {
        font-size: 30px;
    }
}

.contents_box {
    width:637px;
    padding:25px 0 0;
    color:#fff;
    @include poppins-medium;
    font-size: 20px;

    @include breakpoint(d) {
        width: 100%;
        font-size: 24px;
    }

    @include breakpoint(m) {
        font-size: 12px;
    }
}

.buttons_box {
    padding:70px 0 123px;

    @include breakpoint(d) {
        padding: 152px 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;       
    }

    @include breakpoint(m) {
        padding: 76px 0 0;
    }
}

.play_button {
    @include poppins-semibold;
    font-size: 24px;
    padding:14px 25px;
    color:#000;
    border-radius: 90px;
    line-height: 16px;
    opacity: var(--sds-size-stroke-border);

    background: #FFF;
    transition: all 0.1s ease-in-out;

    &:hover {
        scale: 1.1;
        color: #000;
    }

    @include breakpoint(d) {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 410px;
        min-height: 88px;
        font-size: 40px;

        &:hover {
            scale: 1;
        }
    }

    @include breakpoint(m) {
        min-width: 205px;
        min-height: 44px;
        font-size: 20px;
    }
}