@import "../../../../styles/_mixins.scss";
.section {
    position: relative;
    padding: 105px 104px 130px;
    overflow: hidden;

    @include breakpoint(w) {
        padding: 40px 37px 121px 38px;
    }

    @include breakpoint(m) {
        padding: 20px 18px 60px 18px;
    }
}

.background {
    position: absolute;
    left: 50%;
    top: -300px;
    width: 2310px;
    z-index: -1;
    transform: translateX(-52%);

    @include breakpoint(d) {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transform: translateX(0);
    }
}

.pc_img {
    display: block;

    @include breakpoint(d) {
        display: none;
    }
}

.mobile_img {
    position: absolute;
    display: none;

    @include breakpoint(d) {
        display: block;
    }

    &.mobile_img_1 {
        left: 0;
        top: 0;
    }

    &.mobile_img_2 {
        top: 15%;
        right: 0;
    }

    &.mobile_img_3 {
        left: 0;
        bottom: 0;
    }
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    border-radius: 27px;
    background: rgba(117, 70, 219, 0.26);

    @include breakpoint(w) {
        background: none;
    }
}

.menu_panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding: 49px 37px;
    flex: 0 0 193px;
    background: rgba(255, 255, 255, 0.80);
    border-radius: 27px 0 0 27px;

    @include breakpoint(w) {
        display: none;
    }

    .logo_box {
        width: 75px;
        height: 75px;
    }

    .menu_box {
        margin-top: 25px;

        .menu_item {
            color: #595959;
            font-size: 20px;
            text-align: left;
            cursor: pointer;

            &.selected {
                @include poppins-semibold;
            }
        }
    }
}

.detail_panel {
    flex: 1 0 0;
    padding: 38px 33px 38px 27px;

    @include breakpoint(w) {
        padding: 0;
    }
}

.info_box {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: stretch;

    @include breakpoint(w) {
        flex-direction: column-reverse;
    }

    .left_container {
        display: flex;
        flex-direction: column;
        flex: 651 0 651px;
        
        .upper_container {
            flex: 1 0 0;

            @include breakpoint(w) {
                display: none;
            }

            .welcome_box {
                border-radius: 27px;
                display: flex;
                flex-direction: column;
                height: 100%;

                .welcome_text {
                    flex: 0 0 auto;
                    border-radius: 27px 27px 0px 0px;
                    background: rgba(255, 255, 255, 0.5);
                    font-size: 16px;
                    line-height: 40px;
                    color: #11265B;
                    padding: 4px 23px;
                    @include poppins-bold;
                }

                .welcome_my_info {
                    flex: 1 0 auto;
                    background: rgba(17, 38, 91, 0.50);
                    border-radius: 0px 0px 27px 27px;
                    padding: 9px 23px;

                    .my_info_text {
                        font-size: 16px;
                        color: #FFFFFF;
                        line-height: 40px;
                    }
                }
            }
        }

        .lower_container {
            flex: 0 0 461px;
            margin-top:21px;

            @include breakpoint(w) {
                flex: 1 0 auto;
                margin-top: 18px;
            }

            .card_container {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: stretch;
                flex: 0 0 50%;

                &:not(:last-child) {
                    margin-bottom: 21px;
                }

                @include breakpoint(w) {
                    flex: 1 0 auto;
                    flex-direction: column;
                    background: rgba(17, 38, 91, 0.50);
                    border-radius: 15px;
                }

                .info_card {
                    flex: 0 0 calc(50% - 10.5px);
                    padding: 11px 23px 19px;
                    border-radius: 27px;
                    background: rgba(17, 38, 91, 0.50);
                    display: flex;
                    flex-direction: column;

                    @include breakpoint(w) {
                        flex: 1 0 auto;
                        padding: 11px 22px 28px 22px;
                        background: none;
                    }

                    &:not(:last-child) {
                        margin-right: 21px;

                        @include breakpoint(w) {
                            margin-right: 0;
                        }
                    }

                    .upper_box {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                    }

                    .lower_box {
                        margin-top: 9px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        flex: 1;
                    }

                    .title_text,
                    .my_staking_button {
                        @include poppins-bold;
                        font-size: 16px;
                        color: #FFFFFF;
                        line-height: 40px;
                    }

                    .my_staking_balance_box {
                        margin-top: 28px;
                        border-radius: 15px;
                        background: #FFF;
                        padding: 1px 21px 1px 6px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        &:not(:first-child) {
                            margin-top: 16px;
                        }

                        .my_staking_balance_icon_box {
                            width: 55px;
                            height: 55px;
                            margin-right: 10px;
                        }

                        .loading_box {
                            flex: 1 0 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .loading {
                                width: 34px;
                                height: 34px;
                                font-size: 34px;
                                color: rgba(117, 84, 214, 0.26);
                            }
                        }

                        .my_staking_balance_value {
                            @include poppins-bold;
                            font-size: 20px;
                            color: #11265B;
                            flex: 1 0 0;
                            text-align: right;
                        }

                        .my_staking_balance_text {
                            font-size: 14px;
                            color: #11265B;
                            @include poppins-semibold;
                            flex: 0 0 50px;
                            padding-top: 8px;
                            margin-left: 8px;
                        }

                    }

                    .price_card_box {
                        flex: 0 0 50%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        padding: 6px 18px;

                        @include breakpoint(w) {
                            padding: 6px 10px;
                        }

                        .price_card {
                            flex: 0 0 50%;

                            .price_title {
                                font-size: 12px;
                                color: #fff;

                                img {
                                    width: 16.5px;
                                    height: 16.5px;
                                }
                            }

                            .price_text {
                                padding-left: 10px;
                                margin-top: 5px;
                                font-size: 14px;
                                color: #fff;
                                @include poppins-bold;
                                
                            }
                        }
                    }

                    .apy_box {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        .title_text {
                            font-size: 16px;
                            color: #fff;
                            @include poppins-bold;
                            line-height: 40px;
                        }

                        .apy_value_box {
                            font-size: 16px;
                            color: #fff;
                            @include poppins-bold;
                            line-height: 40px;
                        }
                    }

                    .contents_text {
                        color: #FFF;
                        font-size: 16px;
                        @include poppins-semibold;
                        text-align: right;
                        line-height: 20px;
                    }
                }
            }
        }
    }

    .right_container  {
        flex: 291 0 291px;

        .welcome_box_mobile {
            display: none;
            flex-direction: column;
    
            @include breakpoint(w) {
                display: flex;
            }
    
            .welcome_text {
                flex: 0 0 auto;
                border-radius: 15px 15px 0px 0px;
                background: rgba(255, 255, 255, 0.5);
                font-size: 16px;
                line-height: 40px;
                color: #11265B;
                padding: 4px 23px;
                @include poppins-bold;

                @include breakpoint(m) {
                    letter-spacing: -0.16px;
                }
            }
    
            .welcome_my_info {
                flex: 1 0 auto;
                background: rgba(17, 38, 91, 0.50);
                padding: 9px 23px;
    
                .my_info_text {
                    font-size: 16px;
                    color: #FFFFFF;

                    @include breakpoint(m) {
                        font-size: 12px;
                        letter-spacing: -0.84px;
                    }
                }
            }
        }
    
        .profile_box{
            border-radius: 27px;
            background: rgba(17, 38, 91, 0.50);
            margin-left: 21px;
            padding: 28px 17px 31px;

            @include breakpoint(w) {
                border-radius: 0 0 15px 15px;
                margin-left: 0;
            }

            @include breakpoint(m) {
                padding: 16px 22px 31px 21px;
            }

            .address_container {
                text-align: right;

                .address_box {
                    display: inline-block;
                    border-radius: 90px;
                    background: #14284E;
                    color: #FCFCFD;
                    text-align: center;
                    font-size: 14px;
                    @include poppins-bold;
                    padding: 12px 16px;
                }
            }

            .avatar_box {
                margin: 33px auto 0;
                width: 158px;
                height: 158px;
                border-radius: 50%;
                background: #D9D9D9;

                @include breakpoint(m) {
                    margin: 30px auto 19px;
                }
            }

            .balance_container {
                margin-top: 30px;
                max-height: 401px;
                overflow-y: auto;
                padding-right: 11px;
                // scroll style
                &::-webkit-scrollbar {
                    width: 5px;

                    &-track {
                        border-radius: 2px;
                        background: #C5C5C5;
                    }

                    &-thumb {
                        border-radius: 2px;
                        background: #9B9B9B;
                    }
                }

                .title_box {
                    color: #FFF;
                    font-size: 12px;

                    margin-top:13px;
                }
                
                .balance_box {
                    border-radius: 15px;
                    background: #FFF;
                    padding: 3.5px 15px 3.5px 8px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-top: 15px;
        
                    .token_icon {
                        width: 55px;
                        height: 55px;
                    }
            
                    .balance_amount {
                        flex: 1 0 0;
                        color: #11265B;
                        text-align: right;
                        font-size: 20px;
                        @include poppins-bold;
                    }

                    .loading_box {
                        flex: 1 0 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .loading {
                            width: 34px;
                            height: 34px;
                            font-size: 34px;
                            color: rgba(117, 84, 214, 0.26);
                        }
                    }
            
                    .balance_text {
                        flex: 0 0 50px;
                        color: #11265B;
                        font-size: 14px;
                        margin: 8px 0 0 8px;
                        @include poppins-semibold;
                    }
                }
            }
            
        }
    }

}

.chart_box {
    padding: 13px 12px 0;
    width: 100%;
    height: 220px;
    position: relative;

    @include breakpoint(w) {
        padding: 5px 9px 0;
        height: 180px;
    }

    @include breakpoint(m) {
        height: 150px;
    }

    .chart {
        width: 100% !important;
        height: 100% !important;
        position: absolute;
        top: 0;
        left: 0;
    }
}